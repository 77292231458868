import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Grid,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoanSummaryDetailsLayout from "./loan-summary-details/loan-summary-details-layout";
import LoanSummaryLoanStatistics from "./loan-summary-statistices/loan-summary-loan-statistics";
import LoanSummaryTaskBody from "./loan-summary-tasks/loan-summary-task-body";
import { getApiCallDynamic } from "../../../services/api-service";
import { API } from "../../../services/api-config";
import { useParams } from "react-router-dom";
import MainLoyOut from "../../main-layout";
import LoanSummaryMiles from "./loan-summary-miles/loan-summary-miles";
import { InfoOutlined } from "@mui/icons-material";
import { LoanSummaryParentData } from "./loan-summary-visiblity";

import ThirdPartyTaskBody from "./loan-summary-tasks/third-part-task-body";
import BorrowerTaskBody from "./loan-summary-tasks/borrower-task-body";
import { TaskContext } from "../taskContext/TaskContext";
import TaskProvider from "../taskContext/TaskProvider";
import { profileState, draftTaskTriggerState, deleteDraftTaskTriggerState } from "../../../recoil/profile-atom";
import { useRecoilValue } from "recoil";
import { calculateTaskCounts } from "../../../pages/third-party/third-party-util";
import DraftTaskBody from "./loan-summary-tasks/draft-task-body";
import { calculateDraftTaskCounts } from "../../../pages/third-party/draft-task-util";

export default function LoanSummaryLayout() {

  let profileInfo = useRecoilValue(profileState);




  // Variables and functions
  const { id } = useParams();
  const { visiblity } = useContext(LoanSummaryParentData);
  // Use State Start
  // Data Set Start
  const [mainAPIData, setMainAPIData] = useState({});
  const [borrowerListsData, setBorrowerListsData] = useState([]);
  const [changeMloMlpData, setChangeMloMlpData] = useState([]);
  // Data Set End

  const [borrowerFormID, setborrowerFormID] = useState(null);
  const [borrowerTabValue, setBorrowerTabValue] = useState(null);

  const [typeMloMlp, setTypeMloMlp] = useState(null);

  const [taskTabCount, setTaskTabCount] = useState([]);
  const [taskTabValue, setTaskTabValue] = useState(null);
  const [taskTabValueBorrower, setTaskTabValueBorrower] = useState(null);
  const [tasksLists, setTasksLists] = useState({});

  const [thirdPartyTasksLists, setThirdPartyTasksLists] = useState({});
  const [borrowerTasksLists, setBorrowerTasksLists] = useState({});

  // coded by santha for the draft task
  // PS_66	Initialize states for loan summary, borrower lists, tasks, API data,draftTaskCounts,draftTasksLists,taskTabValueDraft
  const [draftTasksLists, setDraftTasksLists] = useState([]);
  const [taskTabValueDraft, setTaskTabValueDraft] = useState(null);

  // Use State End

  const [info, setInfo] = useState({})
  const [loanCode, setLoanCode] = useState(null)
  // Main API Start
  // API
  const mainAPICall = (status) => {
    getApiCallDynamic({
      path: API.loanSummaryMainAPI,
      param: { loanCode: id },
    }).then((res) => {
      if (res?.status === 200) {

        setMainAPIData(res?.data);
        setBorrowerListsData(res?.data?.formInfos);
        setborrowerFormID(res?.data?.formInfos[0].formId);
        setBorrowerTabValue(res?.data?.formInfos[0].formId);
        setLoanCode(res.data.loanSummary?.loanCode)
        const { mloInfo, borrowerInfos, loanSummary } = res?.data || {};
        setInfo({
          mloInfo,
          borrowerInfos,
          loanSummary
        });

      }
    });
  };



  // Reloader
  const mainAPIReloader = () => {
    mainAPICall();
  };
  // Main API End

  // Borrower tab function Start
  const borrowerTabHandleChange = (event, newValue) => {
    setBorrowerTabValue(newValue);
    setborrowerFormID(newValue);
  };

  // Conditions Start
  let borrowerTypeShort = (e) => {
    switch (e) {
      case "Primary":
        return "(PB)";

      case "Co-borrower":
        return "(CB)";

      default:
        return "";
    }
  };
  // Conditions End
  // Borrower tab function End

  // Task Related Code Start
  // Tab value
  const taskTabHandleChange = (event, newValue) => {


    setTaskTabValue(newValue);

  };

  const taskTabHandleChangeBorrower = (event, newValue) => {


    setTaskTabValueBorrower(newValue)
  };

  const taskTabHandleChangeDarft = (event, newValue) => {
    console.log(newValue, 'ppp')

    setTaskTabValueDraft(newValue)
  };

  // API Start
  // Task Start
  const taskTabCounter = (formId) => {
    getApiCallDynamic({
      path: API.taskTabCount,
      param: { formId: formId },
    }).then((res) => {
      if (res?.status === 200) {

        setTaskTabCount(res?.data);
        setTaskTabValue(res?.data[0]?.name);
      }
    });
  };

  const [borrowerTaskCounts, setBorrowerTaskCounts] = useState(null)
  const [thirdPartyTaskCounts, setThirdPartyTaskCounts] = useState(null)
  const [random, setrandom] = useState(null)
  const [allTasks, setAllTasks] = useState(null)
  // coded by santha for the draft task
  // PS_73	Initialize states for loan summary, borrower lists, tasks, API data,draftTaskCounts
  const [draftTaskCounts, setDraftTaskCounts] = useState(null)
  const [draftTaskApiValue, setDraftTaskApiValue] = useState(false)
  const [thirdPartyTaskApiValue, setThirdPartyTaskApiValue] = useState(false)
  const [borrowerTaskApiValue, setBorrowerTaskApiValue] = useState(false)
  // Tasks Lists Body
  const taskListsData = (formId, status) => {
    getApiCallDynamic({
      path: API.taskLists,
      param: { formId: formId, status: status },
    }).then((res) => {
      if (res?.status === 200) {
        setTasksLists(res?.data);
      }
    });
  };
  // Task End


  // Third party Task Lists
  const thirdPartyTaskListsData = (formId, status) => {
    getApiCallDynamic({
      path: API.thirdPartyTaskLists,
      param: { formId: formId, },
    }).then((res) => {
      if (res?.status === 200) {
        console.log(res?.data, 'resdf')
        setAllTasks(res?.data)
        setThirdPartyTaskApiValue(true)
        if (taskTabValue == "Pending") {
          setTaskTabValueDraft('Pending')
          setrandom(Math.random)
        }

        const extractedData = res?.data
          ?.filter(task => task.taskType.taskTypeFor === 'thirdparty' && task?.taskStatus !== 'DRAFTED')
          ?.map(task => ({
            taskType: task?.taskType?.taskTypeName,
            description: task.taskDescription,
            dueDate: task.dueDate,
            priority: task.taskPriority,
            taskStatus: task?.taskStatus,
            taskCode: task.taskCode,
            createdTime: task.taskCreatedTime
          }));

        setThirdPartyTaskCounts(calculateTaskCounts(extractedData))
        setThirdPartyTasksLists(extractedData);

      }
    });
  };


  // Borrower Task Lists
  const borrowerTaskListsData = (formId, status) => {
    getApiCallDynamic({
      path: API.thirdPartyTaskLists,
      param: { formId: formId, },
    }).then((res) => {
      if (res?.status === 200) {
        setAllTasks(res?.data)
setBorrowerTaskApiValue(true)
        const extractedData = res?.data
          ?.filter(task => task?.taskType?.taskTypeFor === 'borrower' && task?.taskStatus !== 'DRAFTED')
          ?.map(task => ({
            taskType: task.taskType.taskTypeName,
            description: task.taskDescription,
            dueDate: task.dueDate,
            priority: task.taskPriority,
            taskStatus: task?.taskStatus,
            taskCode: task.taskCode,
            createdTime: task.taskCreatedTime
          }));

        setBorrowerTaskCounts(calculateTaskCounts(extractedData))
        setBorrowerTasksLists(extractedData);


      }
    });
  };

  // coded by santha for the draft task
  // Draft Task Lists
  // PS_80-PS_81		Fetch and filter draft tasks, calculate draft task counts and recoilValue for the trigger and assign it to the draftTaskTrigger		useEffect(Initial Load)	Trigger main API call on component mount
  const draftTaskTrigger = useRecoilValue(draftTaskTriggerState);
  const deleteDraftTaskTrigger = useRecoilValue(deleteDraftTaskTriggerState);

  useEffect(() => {
    if (borrowerFormID) {
      draftTaskListsData(borrowerFormID);
    }
  }, [draftTaskTrigger, borrowerFormID]);

  useEffect(() => {
    if (borrowerFormID) {
      draftTaskListsData(borrowerFormID);
    }
  }, [deleteDraftTaskTrigger, borrowerFormID]);

  const draftTaskListsData = (formId) => {
    getApiCallDynamic({
      path: API.thirdPartyTaskLists,
      param: { formId: formId, },
    }).then((res) => {
      if (res?.status === 200) {
        setAllTasks(res?.data)
        setDraftTaskApiValue(true)
        console.log("result for the data in load ", res?.data)
        console.log("extractedData res :====>", res.data);

        const tabValueData = res?.data

          ?.filter(task => task.taskStatus === 'DRAFTED') // Corrected filtering condition
          ?.map(task => ({
            taskType: task?.taskType?.taskTypeName,
            description: task?.taskDescription,
            dueDate: task?.dueDate,
            priority: task?.taskPriority,
            taskStatus: task?.taskStatus,
            taskCode: task?.taskCode,
            createdTime: task?.taskCreatedTime,
            taskTypeFor: task?.taskType.taskTypeFor,
            taskTypeId: task?.taskType.id,
            deleted: task?.deleted,
            editDBRowID: task?.id

          }));
        console.log("tabValueDatatabValueData", tabValueData)
        const extractedData = res?.data
          ?.filter(task => task?.taskStatus === 'DRAFTED') // Corrected filtering condition
          ?.map(task => ({
            taskType: task?.taskType?.taskTypeName,
            description: task?.taskDescription,
            dueDate: task?.dueDate,
            priority: task?.taskPriority,
            taskStatus: task?.taskStatus,
            taskCode: task?.taskCode,
            createdTime: task?.taskCreatedTime,
            taskTypeFor: task?.taskType.taskTypeFor,
            taskTypeId: task?.taskType.id,
            deleted: task?.deleted,
            editDBRowID: task?.id
          }));


        console.log("extractedData :====>", extractedData);

        setDraftTaskCounts(calculateDraftTaskCounts(tabValueData))
        setDraftTasksLists(extractedData);


      }
    });
  };


  const getLoanStateDate = () => {
    let date = ''
    mainAPIData.miles.forEach((item) => {
      if (item.mileName === mainAPIData.loanState) {
        date = item.mileDate
      }
    })
    return date
  }





  const taskReload = () => {
    //  taskTabCounter(borrowerFormID);
    //  taskListsData(borrowerFormID, taskTabValue);
    thirdPartyTaskListsData(borrowerFormID, taskTabValue)
    borrowerTaskListsData(borrowerFormID, taskTabValue)
    // coded by santha for the draft task
    // PS_73 Initialize and call states for loan summary, borrower lists, tasks, API data,draftTaskListsData
    draftTaskListsData(borrowerFormID, taskTabValue)
  };
  // Task Related Code End

  // MLO MLP API
  const changeMloMlpAPI = (userType) => {
    getApiCallDynamic({
      path: API.loanChangeMloMlp,
      param: { loanCode: id, type: userType },
    }).then((res) => {
      if (res?.status === 200) {
        setChangeMloMlpData(res?.data);
      }
    });
  };

  const changeUserType = (value) => {
    setTypeMloMlp(value);
  };

  // API End

  // Use Effect Start
  // 1st load
  useEffect(() => {
    mainAPICall();
  }, []);

  // Borrower Tab
  useEffect(() => {
    if (borrowerTabValue !== null) {
      // taskListsData(borrowerFormID, taskTabValue);
      //  taskTabCounter(borrowerTabValue);
      thirdPartyTaskListsData(borrowerFormID, taskTabValue)
      borrowerTaskListsData(borrowerFormID, taskTabValue)
      // coded by santha for the draft task
      // PS_73 Initialize and call states for loan summary, borrower lists, tasks, API data,draftTaskListsData
      draftTaskListsData(borrowerFormID, taskTabValue)
    }
  }, [borrowerTabValue]);




  useEffect(() => {

    const fetchDataAndFilterTasks = async () => {
      if (taskTabValue !== null) {


        const extractedData = allTasks
          ?.filter(task => task.taskType.taskTypeFor === 'thirdparty' && task.taskStatus !== 'DRAFTED')
          ?.map(task => ({
            taskType: task?.taskType?.taskTypeName,
            description: task.taskDescription,
            dueDate: task.dueDate,
            priority: task.taskPriority,
            taskStatus: task?.taskStatus,
            taskCode: task.taskCode,
            createdTime: task.taskCreatedTime
          }));

        // Now filter tasks based on the updated thirdPartyTasksLists
        const filterTasks = (taskTabValue) => {
          let filteredTasks;
          const normalizedTaskTabValue = taskTabValue.toLowerCase();

          switch (normalizedTaskTabValue) {
            case 'completed':
              filteredTasks = extractedData.filter(
                task => task.taskStatus.toLowerCase() === normalizedTaskTabValue
              );
              break;
            case 'resolved':
              filteredTasks = extractedData.filter(
                task => task.taskStatus.toLowerCase() === normalizedTaskTabValue
              );
              break;

            case 'high':
            case 'low':
              filteredTasks = extractedData.filter(
                task => task?.priority?.toLowerCase() === normalizedTaskTabValue
              );
              break;

            case 'active':
              filteredTasks = extractedData.filter(
                task =>
                  task.taskStatus.toLowerCase() !== 'completed' &&
                  task.taskStatus.toLowerCase() !== 'resolved' &&
                  task.taskStatus.toLowerCase() !== 'drafted'
              );
              break;

            default:
              filteredTasks = extractedData;
          }


          setThirdPartyTasksLists(filteredTasks);
        };

        // Call the filter function with the current taskTabValue
        filterTasks(taskTabValue);
      }
    };

    fetchDataAndFilterTasks();
  }, [taskTabValue]);
  useEffect(() => {

    const fetchDataAndFilterTasks = async () => {
      if (taskTabValueBorrower !== null) {


        const extractedData = allTasks
          ?.filter(task => task.taskType.taskTypeFor === 'borrower' && task.taskStatus !== 'DRAFTED')
          ?.map(task => ({
            taskType: task?.taskType?.taskTypeName,
            description: task.taskDescription,
            dueDate: task.dueDate,
            priority: task.taskPriority,
            taskStatus: task?.taskStatus,
            taskCode: task.taskCode,
            createdTime: task.taskCreatedTime
          }));

        // Now filter tasks based on the updated thirdPartyTasksLists
        const filterTasks = (taskTabValueBorrower) => {
          let filteredTasks;
          const normalizedTaskTabValue = taskTabValueBorrower.toLowerCase();

          switch (normalizedTaskTabValue) {
            case 'completed':
              filteredTasks = extractedData.filter(
                task => task.taskStatus.toLowerCase() === normalizedTaskTabValue
              );
              break;
            case 'resolved':
              filteredTasks = extractedData.filter(
                task => task.taskStatus.toLowerCase() === normalizedTaskTabValue
              );
              break;

            case 'high':
            case 'low':
              filteredTasks = extractedData.filter(
                task => task?.priority?.toLowerCase() === normalizedTaskTabValue
              );
              break;

            case 'active':
              filteredTasks = extractedData.filter(
                task =>
                  task.taskStatus.toLowerCase() !== 'completed' &&
                  task.taskStatus.toLowerCase() !== 'resolved' &&
                  task.taskStatus.toLowerCase() !== 'drafted'
              );
              break;

            default:
              filteredTasks = extractedData;
          }


          setBorrowerTasksLists(filteredTasks);
        };

        // Call the filter function with the current taskTabValue
        filterTasks(taskTabValueBorrower);
      }
    };

    fetchDataAndFilterTasks();
  }, [taskTabValueBorrower]);

  // coded by santha for draft task
  // PS_82		useEffect (draft Tab)	Fetch tasks when draft tab values for the draft task body
  useEffect(() => {

    const fetchDataAndFilterTasks = async () => {
      console.log(taskTabValueDraft, 'taskTabValueDraft', allTasks)
      if (taskTabValueDraft !== null) {

        // && task?.deleted === false
        const extractedData = allTasks
          ?.filter(task => task.taskStatus === 'DRAFTED')
          ?.map(task => ({
            taskType: task?.taskType?.taskTypeName,
            description: task.taskDescription,
            dueDate: task.dueDate,
            priority: task.taskPriority,
            taskTypeFor: task?.taskType.taskTypeFor,
            taskStatus: task?.taskStatus,
            taskCode: task.taskCode,
            taskTypeId: task?.taskType?.id,
            createdTime: task.taskCreatedTime,
            deleted: task?.deleted,
            editDBRowID: task?.id
          }));

        // Now filter tasks based on the updated thirdPartyTasksLists
        const filterTasks = (taskTabValueDraft) => {
          let filteredTasks;
          const normalizedTaskTabValue = taskTabValueDraft?.toLowerCase();
          console.log(normalizedTaskTabValue, 'normalizedTaskTabValue', extractedData)

          // switch (normalizedTaskTabValue) {
          //   case 'pending':
          //     filteredTasks = extractedData.filter(
          //       // console.log(task, 'filteredTasks')
          //       task => task?.deleted === false
          //     );
          //     console.log(filteredTasks, 'filteredTasks')
          //     break;
          //   case 'deleted':
          //     filteredTasks = extractedData.filter(
          //       // console.log(task, 'filteredTasks')
          //       task => task?.deleted === true
          //     );
          //     break;
          //   default:
          //     filteredTasks = extractedData;
          // }
          if (normalizedTaskTabValue === 'pending') {
            filteredTasks = extractedData.filter(task => task?.deleted === false);
            console.log(filteredTasks, 'filteredTasks');
          } else if (normalizedTaskTabValue === 'deleted') {
            filteredTasks = extractedData.filter(task => task?.deleted === true);
          } else {
            filteredTasks = extractedData;
          }

          console.log(filteredTasks, 'filteredTaskss')
          setDraftTasksLists(filteredTasks);
        };

        // Call the filter function with the current taskTabValue
        filterTasks(taskTabValueDraft);
      }
    };

    fetchDataAndFilterTasks();
  }, [taskTabValueDraft]);

  useEffect(() => {
    console.log(draftTasksLists, 'draftTasksLists')
  }, [draftTasksLists])


  // Change MLO MLP
  useEffect(() => {
    if (typeMloMlp !== null) {
      changeMloMlpAPI(typeMloMlp);
    }
  }, [typeMloMlp]);
  // Use Effect End

  // State to manage the currently selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  // Function to handle tab changes
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  //const [selectedTab, setSelectedTab] = React.useState('1');

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };
  return (
    <MainLoyOut>
      {/* Main container and box for padding on this page */}
      {/* Tab of loan users start */}
      <TabContext value={borrowerTabValue}>
        {/* Tab navigation */}
        <Box bgcolor={"white"} sx={{ borderBottom: 1, borderColor: "divider", display: visiblity ? 'block' : 'none' }}>
          <TabList
            onChange={borrowerTabHandleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="Loan Summary Task Tab"
          >
            {borrowerListsData
              // Checking Borrower Type
              .sort((a) => (a.borrowerType == "Primary" ? -1 : 1))
              .map((borrowerData) => (
                <Tab
                  key={borrowerData.formId}
                  label={
                    borrowerData.firstName +
                    " " +
                    borrowerData.lastName +
                    " " +
                    borrowerTypeShort(borrowerData.borrowerType)
                  }
                  value={borrowerData.formId}
                />
              ))}

            <Box ml={"auto"} my={"auto"} px={2}>
              <Tooltip
                arrow
                placement="left"
                color="A8"
                title={
                  <>
                    <Typography
                      component={"span"}
                      variant="body2"
                      color={"primary.main"}
                    >
                      {`Note : `}
                    </Typography>
                    <Typography component={"span"} variant="body2">
                      {`Here we have use few abbreviation`}
                    </Typography>
                    <Typography component={"span"} variant="body2">
                      {`ie. PB (Primary Borrower) | CB (Co-Borrower here)`}
                    </Typography>
                  </>
                }
              >
                <InfoOutlined />
              </Tooltip>
            </Box>
          </TabList>
        </Box>
        {/* Tab Content */}
        {borrowerListsData.map((borrowerData) => (
          <TabPanel
            key={borrowerData.formId}
            value={borrowerData.formId}
            sx={{ marginTop: 3, padding: 0 }}
          >
            <LoanSummaryDetailsLayout
              loanState={mainAPIData.loanState}
              loanStateDate={getLoanStateDate()}
              lockStatus={mainAPIData.loanSummary.lockStatus}
              lockExpirationDate={mainAPIData.loanSummary.lockExpirationDate}
              borrowerData={borrowerData}
              mloData={mainAPIData.mloInfo}
              mlpData={mainAPIData.mlpInfo}
              userType={changeUserType}
              mloMlpList={changeMloMlpData}
              loanCode={id}
              reload={mainAPIReloader}
            />

            <Box>
              <Grid container rowSpacing={1} columnSpacing={2}>
                <Grid item xs={12} md={5}>
                  <Stack direction={"column"} gap={2}>
                    <LoanSummaryLoanStatistics
                      loanSummaryLoanStatisticsData={mainAPIData.loanSummary}
                      statisticsReloader={mainAPIReloader}
                    />
                    <LoanSummaryMiles
                      loanCode={id}
                      loanState={mainAPIData.loanState}
                      milesData={mainAPIData.miles}
                      milesReloader={mainAPIReloader}
                    />
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} md={7}>
                  <LoanSummaryTaskBody
                    formId={borrowerFormID}
                    borrowerName={
                      borrowerData.firstName + " " + borrowerData.lastName
                    }
                    taskTabCount={taskTabCount}
                    tasksLists={tasksLists}
                    taskReload={taskReload}
                    taskTabValue={taskTabValue}
                    taskTabHandleChange={taskTabHandleChange}
                  />
                </Grid> */}
                <Grid item xs={12} md={7}>
                  <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      
                      <TabList onChange={handleTabChange} aria-label="custom tabs example" disabled={!(draftTaskApiValue && borrowerTaskApiValue && thirdPartyTaskApiValue)} >
                        <Tab label="Borrower Tasks" value="1" disabled={!(draftTaskApiValue && borrowerTaskApiValue && thirdPartyTaskApiValue)} />
                        {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
                          || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
                        ) && (
                            <Tab label="Third Party Tasks" value="2" disabled={!(draftTaskApiValue && borrowerTaskApiValue && thirdPartyTaskApiValue)} />)}
                        {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
                          || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
                        ) && (
                            <Tab label="Draft Tasks" value="3" disabled={!(draftTaskApiValue && borrowerTaskApiValue && thirdPartyTaskApiValue)} />
                          )}
                      </TabList>
                    </Box>
                    <TabPanel value="1">

                      <BorrowerTaskBody
                        formId={borrowerFormID}
                        loanCode={loanCode}
                        borrowerName={
                          borrowerData.firstName + " " + borrowerData.lastName
                        }
                        taskTabCount={borrowerTaskCounts}
                        tasksLists={borrowerTasksLists}  // card lists
                        taskReload={taskReload}
                        taskTabValueBorrower={taskTabValueBorrower}
                        info={info}
                        taskTabHandleChangeBorrower={taskTabHandleChangeBorrower}
                        profileInfo={profileInfo}
                      />

                    </TabPanel>
                    {(profileInfo.role === 'MLO' || profileInfo.role === 'MLO Owner'
                      || profileInfo.role === 'Broker' || profileInfo.role === 'MLP'
                    ) && (
                        <TabPanel value="2">
                          <ThirdPartyTaskBody
                            formId={borrowerFormID}
                            loanCode={loanCode}
                            borrowerName={
                              borrowerData.firstName + " " + borrowerData.lastName
                            }
                            taskTabCount={thirdPartyTaskCounts}
                            tasksLists={thirdPartyTasksLists} // card lists
                            taskReload={taskReload}
                            taskTabValue={taskTabValue}
                            info={info}
                            taskTabHandleChange={taskTabHandleChange}
                            profileInfo={profileInfo}

                          />
                        </TabPanel>
                      )}
                    <TabPanel value="3">
                      <DraftTaskBody
                        formId={borrowerFormID}
                        loanCode={loanCode}
                        borrowerName={
                          borrowerData.firstName + " " + borrowerData.lastName
                        }
                        taskTabCount={draftTaskCounts}
                        tasksLists={draftTasksLists} // card lists
                        taskReload={taskReload}
                        taskTabValue={taskTabValueDraft}
                        info={info}
                        taskTabHandleChange={taskTabHandleChangeDarft}
                        taskTabValueDraft={taskTabValueDraft}
                      />
                    </TabPanel>

                  </TabContext>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        ))}
      </TabContext>

      {/* Tab of loan users end */}
    </MainLoyOut>
  );
}