import { API } from "../../services/api-config";
import { getApiCallDynamic, getApiCallDynamicWithHeader } from "../../services/api-service";

// PS_36    Fetch details of the draft task using the API
export const fetchDraftDetails = async (taskCode) => {




    // PS_37    Get the response by calling getApiCallDynamic

    try {
        const res = await getApiCallDynamic({
            path: API.getThirdPartyTask,
            param: { thirdPartyTaskCode: taskCode },
            //  token: accessCode
        });
        // PS_38    Handle the response, checking for status 200 and logging errors
        if (res?.status === 200) {
            console.error('Ftech data :', res);
            //setThirdPartyTaskDetails(res.data);
        } else {
            console.error('Unexpected response status:', res);
        }

        return res?.data;
    } catch (error) {
        // PS_39   Catch any errors during the API call and log them
        console.error('Error fetching third-party details:', error);
    }
};


// PS_40    create a function named getTaskStatusLabel Get the label for the task status
export const getTaskStatusLabel = (status) => {
    switch (status) {
        case "deleted":
            return "Deleted";
        case "CREATED":
        case "INCOMPLETE":
        case "PARTIALCOMPLETE":
        case "RESUMELATER":
            return "Pending";
        default:
            return status;
    }
};

// PS_41    Calculate counts for different task statuses
export const calculateDraftTaskCounts = (data) => {
console.log(data,";;[[]]]]")
    let allTasksCount = data.length;
    let deletedTasksCount = 0;
    let PendingTaskCount = 0;

    // PS_42    Iterate over each task to count the number of deleted and pending tasks
    data.forEach(task => {

        if (task.deleted === true) {
            deletedTasksCount++;
            console.log("deletedTasksCount    ", deletedTasksCount)
        }  else {
            PendingTaskCount++;
        }
    });

    // PS_43    Return the counts of all, pending, and deleted tasks
    return [
        { name: "All", count: allTasksCount },
        { name: "Pending", count: PendingTaskCount },
        { name: "Deleted", count: deletedTasksCount },

    ];
};



