import { atom } from "recoil";
import { DEFAULT_ALERET_SEVERITY } from "../constants/constatnt-variable";

export const profileState = atom({
  key: "profile",
  default: {},
});

export const webInfoState = atom({
  key: "webInfoState",
  default: {
    bio: "",
  },
});

export const formState = atom({
  key: "form",
  default: {
    getStarted: {
      page: {
        coBorrower: [],
        partner: null,
        hasPartner: null,
        coborrowersPartner: [],
        primaryBorrowerPartner: null,
        brokerCode: null
      }
    },
    assetsCredits: {
      page: [],
    },
    loanDetails: {
      page: null
    },
    borrowerInfo: {
      page: {
        personal: {
          subPage: null
        },
        contact: {
          subPage: null
        },
        declarations: {
          subPage: null
        },
        militaryServices: {
          subPage: null
        },
        additionalIncome: {
          subPage: null
        },
        dependent: {
          subPage: null
        },
        demographics: {
          subPage: null
        },
      }
    },
    gift: {
      page: []
    },
    realEstate: {
      page: []
    },
    liabilities: {
      page: []
    },
    id: null,

  },
});

export const globalSpinner = atom({
  key: "globalSpinnerState",
  default: false,
});


// Alert State
export const GlobalAlertSeverity = atom({
  key: "GlobalAlertSeverityState",
  default: DEFAULT_ALERET_SEVERITY
});


// coded by santha draft task
export const draftTaskTriggerState = atom({
  key: 'draftTaskTriggerState',
  default: 0
});

export const draftTaskPopUpTriggerState = atom({
  key: 'draftTaskPopUpTriggerState',
  default: 0
});

export const deleteDraftTaskTriggerState = atom({
  key: 'deleteDraftTaskTriggerState',
  default: 0
});