import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../helpers/custom-input";
import { DialogCommonHooks } from "../../../helpers/modal/modal";
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from "../../../services/api-service";
import { formState } from "../../../recoil/profile-atom";
import { API } from "../../../services/api-config";
import { UPDATE } from "../../../constants/constatnt-variable";
import PreferenceForm from "./preference-form";
import { CountDown } from "../../../helpers/time-counter";
import { useRecoilValue, useRecoilState } from "recoil";

function Preferences() {
  const [open, setOpen] = useState();
  const [otp, setOtp] = useState(' ');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [emailOtpCount] = CountDown({ sec: 60 });
  let [formData, setFormData] = useRecoilState(formState)

  console.log("formDaformDaformDaformDaformDa", formData)

  const [creditReportData, setCreditReportData] = useState()
  const [isCredcoAccount, setHasCredCoAccount] = useState(null);
  const [isNonCredcoAccount, setHasDifferentProvider] = useState(null);
  useEffect(() => {
    getApiCallDynamic({ path: API.preferenceCredit }).then((res) => {
      if (res?.status === 200) {
        setCreditReportData(res?.data?.creditReportPullPreference);
      }
    });
    getApiCallDynamic({ path: API.getCredcoAccount,
      param: {
        loanCode: formData.loanCode
      }
     })
      .then((res) => {
        if (res?.status === 200) {
          // Set values fetched from API
          setHasCredCoAccount(res.data.isCredcoAccount);
          setHasDifferentProvider(res.data.isNonCredcoAccount);
          setSpinner(false);
          setFinalValidationReset(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching CredCo account status:', error);
      });
  }, [])

  const [value, setValue] = useState(creditReportData);

  const handleChange = async (event) => {
    setValue(event.target.value);
    if (event.target.value) {
      await postApiCallDynamicWithOutReturn({
        data: { creditReportPullPreference: event.target.value },
        path: API.preferenceCredit,
        header: UPDATE
      });
    }
  };


  if (creditReportData == undefined) {
    return <></>
  }
  return (
    <>
      <Grid container>
        {/* Left Column in md */}
        <Grid item container maxWidth={{ xs: '100%', md: '80%' }}>
          <Grid item sm={12} md={6} my={1}>
            <Typography variant="h4">
              Do you want to pull Credit Reports<br></br>for all of your borrower applications
            </Typography>
          </Grid>
          <Grid item container sm={12} md={4} my={1}>
            <PreferenceForm creditReportData={creditReportData} handleChange={handleChange} value={value} isCredcoAccount={isCredcoAccount} />
            </Grid>
        </Grid>

        {/* <Grid item container maxWidth={{ xs: '100%', md: '80%' }}>
          <Grid item xs={12} sm={12} md={6} my={1}>
            <Typography variant="h4">Disable email OTP verification</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} my={1}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no"
                name="radio-buttons-group"
              >
                <FormControlLabel value="no" control={<Radio />} label="No" />

                <FormControlLabel
                  onClick={handleOpen}
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid> */}
      </Grid>
      <DialogCommonHooks
        title={`Verification`}
        show={open}
        handleClose={handleClose}
        body={
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography sx={{ marginTop: 5 }}>
                Please enter the verification code sent to your mobile number
              </Typography>
            </Grid>
            <Grid item xs={12} >
              <Grid display={'flex'} justifyContent={'space-between'}>
                <Link>Resend</Link>
                {emailOtpCount.counter == 0 ? (
                  <Typography
                    component="button"
                    // id={state.from}
                    value="email"
                  // onClick={resendOtp}
                  >
                    Resend
                  </Typography>
                ) : (
                  emailOtpCount.obj.m + " min " + emailOtpCount.obj.s + " sec"
                )}
              </Grid>
              <CustomInput change={(e) => setOtp(e)}
                error={!otp.length ? 'Please enter the verification code' : null}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={!otp.length}
                variant="contained"
                size="large"
                sx=
                {{
                  width: '100%'
                }}
              >
                VERIFY
              </Button>
            </Grid>
          </Grid>
        }
      />

      {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 599,
                    height: 662,
                    bgcolor: '#FFFFFF',
                    border: '2px solid #000',
                    // boxShadow: 24,
                    p: 4,
                }}
                >
                    <Grid container spacing={3} direction={"column"} >
                        <Grid item>
                            <Typography>
                                Please enter the verification code sent to your mobile number
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link>Resend</Link>
                            <CustomInput />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                sx={{
                                    borderColor: "#FFFFFF !important",
                                    color: "#FFFFFF !important",
                                    fontWeight: "bold",
                                    width: "250px",
                                }}
                            >
                                VERIFY
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal> */}
    </>
  );
}

export default Preferences;
