import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent } from '@mui/material';
import CustomRadioChip from "../../../../helpers/radio-chip";
import { DraftTaskUploadConditionForm } from './draft-task-confirmation-form';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    Stack,
    Typography,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import { KSCloudDownloadIcon, KSDocumentSearchIcon, KSDeleteIcon } from "../../../../icons/custome-icon";
import { kstheme } from "../../../../theme/theme";
import { Controller, useForm, useWatch, } from "react-hook-form";
import {
    CONTACT_STATE,
    CREATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group-doc";
import {
    getApiCallDynamic,
    postApiCallDynamicWithOutReturn,
    uploadDocCustom,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { AlertSeverity, toastNotify } from "../../../../helpers/alert-msg";
import { TaskTypeValidation, TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";

import SupportingDocumentForm from "./supporting-doc-form";
import SupportingDocument from "./supporting-doc";
import PreviewTask from "./preview-task";
import { DocumentDialogCommonHooks } from "../../../../helpers/modal/modal";
import CustomInputMultiLine from "../../../../helpers/custom-input-multiline";
import { TaskContext } from "../../taskContext/TaskContext";
import { formState, draftTaskTriggerState, draftTaskPopUpTriggerState } from '../../../../recoil/profile-atom';

import { useRecoilValue } from "recoil";
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';







export default function UploadConditionForm({
    formId,
    loanCode,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
    taskList,
    taskReceiverDetails,
    info,


}) {


    const { taskState, updateContextBorrowerTasks, updateContextThirdPartyTasks,

        insertSupportingDoc,
        deleteSupportingDoc,
        updateSupportingDoc,
        clearSupportingDocs,

    } = useContext(TaskContext);

    const { contextSupportingDocs } = taskState;
    const {
        control,
        handleSubmit,
        formState: { errors }, setValue, getValues
    } = useForm({
        defaultValues: {

        },
    });

    const [apiCallData, setApiCallData] = useState(null);

    console.log("handleClosehandleClosehandleClose", handleClose);

    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName,
        value: task.id,
    }));

    const [taskTypeTitle, setTaskTypeTitle] = useState(null)
    const getTaskTypeNameById = (id) => {
        const task = taskList.find((task) => task.id === id);
        return task ? task.taskTypeName : null;
    };


    const { taskType, taskReceiver } = useWatch({ control })

    useEffect(() => {

        if (taskType) {



            setTaskTypeTitle(getTaskTypeNameById(taskType))

        }
    }, [taskType]);





    useEffect(() => {

        if (taskReceiver) {
            const matchedReceiver = taskReceiverDetails.find(item => item.name === taskReceiver);
            setValue('receiverName', matchedReceiver?.name)
            setValue('receiverPhoneNo', matchedReceiver?.phone)
            setValue('receiverEmail', matchedReceiver?.email)
            setFormatWorkPhone(formatPhoneNumber(matchedReceiver?.phone))
        } else {
            setValue('receiverName', null)
            setValue('receiverPhoneNo', null)
            setValue('receiverEmail', null)
        }
    }, [taskReceiver]);

    const [titles, setTitles] = useState([])
    const [taskDocs, setTaskDocs] = useState([])
    const [requiredDocs, setRequiredDocs] = useState([])
    const [supportingDocs, setSupportingDocs] = useState([])
    // coded by santha for the navigation of popup
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [changedis, setchangedis] = useState('');
    let [formValue, setFormValue] = useRecoilState(formState);
    const [uploadedFileName, setUploadedFileName] = useState(null); // State for file preview
    const [file, setFile] = useState(null); // State for file upload
    const [fileContent, setFileContent] = useState('');
    const [filePreviewUrl, setFilePreviewUrl] = useState(null);
    const location = useLocation();
    const statusCode = location.state?.statusCode;
    console.log("dialogue box open :", isDialogOpen)

    useEffect(() => {

        if (taskType) {
            const path = API.getMasterTaskDocByTaskID() + taskType

            const taskListsData = () => {


                getApiCallDynamic({

                    path


                })
                    .then((res) => {
                        if (res?.status === 200) {
                            const tasks = res?.data || [];
                            setTaskDocs(tasks);


                            setRequiredDocs(res.data)

                            const titles = res?.data
                                .filter(item => item.documentType !== undefined)
                                .map(item => item.documentType);


                            setTitles(titles);


                            const supportingDocs = res?.data
                                .filter(item => item.blankDocument === true)
                                .map(({ documentType, ...rest }) => ({
                                    ...rest,
                                    documentName: documentType,
                                }));


                            clearSupportingDocs()

                            if (supportingDocs && supportingDocs.length > 0) {
                                supportingDocs.forEach(doc => insertSupportingDoc(doc));
                            }













                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            };

            taskListsData();
        }
    }, [taskType]);










    const nameObjects = taskReceiverDetails.map(item => ({ label: item.name, value: item.name }));

    const jsonNameObjects = JSON.stringify(nameObjects, null, 2);


    const formOnSubmit = async (data) => {





        const requiredDocuments = requiredDocs.filter(doc =>
            data?.requiredDocs?.includes(doc.documentType))
            .map(({ id, storageKey, fileName, ...rest }) => ({
                ...rest,
                blankDocStorageKey: storageKey,
                blankDocFileName: fileName,
                blankDocId: id
            }));


        const apiData = {
            //  "id": 123,
            "taskCode": null,
            "taskTypeName": taskTypeTitle,
            "loanCode": null,
            "formId": formId,
            "taskType": {
                "id": data?.taskType,

            },
            "taskDescription": data?.taskDescription,
            "dueDate": data?.dueDate,
            "taskPriority": data?.taskPriority,
            "lenderCondition": data?.isLender === 'Yes' ? true : false,

            requiredDocuments,

            supportingSampleDocuments: contextSupportingDocs,

            "taskStatus": null,
            "taskReceiverName": data?.receiverName,
            "taskReceiverPhoneNumber": data?.receiverPhoneNo,
            "taskReceiverEmail": data?.receiverEmail
        }


        setApiCallData(apiData);

        await postApiCallDynamicWithOutReturn({
            data: apiData,
            path: API.getThirdPartyTask,
            header: edit ? UPDATE : CREATE,
            refresh: taskReload,
        });

        handleClose();
    };

    const [finalClick, setFinalClick] = useState(false);

    const { watch } = useForm();



    const handlePreview = () => {
        // cocde by santha for the popup
        setIsDialogOpen(true);

        setIsPreview(true);
    };

    const handleButtonClick = () => {
        setFinalClick(true);
    };


    const [isPreview, setIsPreview] = useState(false);
    const handleSend = () => {

        handleSubmit(formOnSubmit)();
    };
    const handleCancelPreview = () => {
        setIsPreview(false);
    };










    const [selectAll, setSelectAll] = useState(false);


    // Handle the "Select All" toggle
    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const selectedOptions = isChecked ? titles : [];
        setValue('requiredDocs', selectedOptions);
    };



    const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(null));
    const [unformatWorkPhone, setUnformatWorkPhone] = useState(null);
    const [validationMessage, setValidationMessage] = useState("");

    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return phoneNumber;
    }

    function handleWorkPhoneChange(event) {
        let unformattedValue = event.target.value.replace(/\D/g, '');

        if (unformattedValue.length > 10) {
            unformattedValue = unformattedValue.slice(0, 10);
        }

        setUnformatWorkPhone(unformattedValue);
        setFormatWorkPhone(formatPhoneNumber(unformattedValue));
    }

    function handleWorkPhoneChangeDB() {
        setValue('receiverPhoneNo', unformatWorkPhone)
    }

    // coded by santha for the popup ;

    const handleChange = (event) => {
        console.log(event, '***')
        setFile(event.target.files[0]);
        document.getElementById('fileInput').value = null;

    };


    useEffect(() => {
        if (file) {
            setUploadedFileName(file.name);
            const reader = new FileReader();
            reader.onload = function (event) {
                setFilePreviewUrl(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }, [file]);


    const handleValidationResult = (result) => {
        if (!result.success) {
            setValidationMessage(result.message || "Invalid document type");
        } else {
            setValidationMessage(""); // Clear message on success
        }
    };



    const deleteDocument = () => {
        setFile("");
        setUploadedFileName("");
        setFilePreviewUrl("");
    };

    const handleUploadFileChange = (e) => {
        // // const selectedFile = e.target.files[0];
        // // setFile(selectedFile);

        // const reader = new FileReader();
        // reader.onload = (e) => {
        //     setFileContent(reader.result); // reader.result contains the file content
        // };

        if (file) {
            const fileURL = URL.createObjectURL(file); // Create a URL for the uploaded file
            window.open(fileURL); // Open the PDF in a new tab or window
        }
    };
    const [uploadedTasks, setUploadedTasks] = useState([]);

    const handleUploadSuccess = (tasks) => {
        setUploadedTasks(tasks); // Update the tasks state with API response

    };

    const draftTaskPopupClose = useRecoilValue(draftTaskTriggerState);
    console.log(draftTaskPopupClose, 'draftTaskPopupClose')
    useEffect(() => {
        console.log(draftTaskPopupClose, 'draftTaskPopupClosedraftTaskPopupClose')
        setIsDialogOpen(false); // Close the dialog
    }, [draftTaskPopupClose]);
    console.log(isDialogOpen, 'isDialogOpen')
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
    return (
        <>
            <AlertSeverity />
            {isDialogOpen ? (

                <Box onClose={() => {
                    setIsDialogOpen(false)
                    handleClose()
                }}>

                    <DraftTaskUploadConditionForm
                        handleClose={() => {
                            setIsDialogOpen(false)
                            handleClose()
                        }}
                        formId={formId}
                        file={file}
                        onValidationResult={handleValidationResult} // Pass callback
                        onUploadSuccess={handleUploadSuccess}
                    />
                </Box>
            ) : (
                <form onSubmit={handleSubmit(formOnSubmit)}>
                    <Box height={'400px'} >
                        <Typography variant="h3" paragraph>Upload Conditions</Typography>

                        <Box p={2} mb={5} border={'1px solid rgba(17, 17, 17, 0.10)'} borderRadius={'4px'} width={isMobile ? '100%' : '100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box>
                                <Typography variant='h4'>{`Conditions `}</Typography>
                                <Typography variant='p' color={'#222222BF'}>{uploadedFileName}</Typography>
                            </Box>
                            <Stack direction={'column'} gap={1} justifyContent="flex-start" alignItems="flex-start" >
                                <Stack direction={'row-reverse'} gap={1} alignSelf={{ xs: 'center', md: 'auto' }}>
                                    <IconButton color='primary' component="label"
                                    >
                                        <input
                                            id="fileInput"
                                            onChange={(e) => { handleChange(e) }}
                                            hidden
                                            //accept="image/*"
                                            type="file"
                                        />
                                        <KSCloudUploadIcon />
                                    </IconButton>
                                    {uploadedFileName && (
                                        <IconButton color='primary' onClick={handleUploadFileChange}>
                                            <KSDocumentSearchIcon />
                                        </IconButton>
                                    )}
                                    {uploadedFileName && (
                                        <IconButton color='primary' onClick={deleteDocument}>
                                            <KSDeleteIcon />
                                        </IconButton>
                                    )}
                                </Stack>
                            </Stack>

                        </Box>
                        {validationMessage && (
                            <Typography color="error">{validationMessage}</Typography>
                        )}
                    </Box >



                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} mt={5} mb={5}>
                        <Button
                            variant="contained"
                            onClick={() => handlePreview(
                                getValues()
                            )}
                            disabled={file ? false : true}
                        >
                            Create Draft Tasks
                        </Button>
                        <Button variant="text" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Stack>
                </form>

            )}

        </>
    );
}