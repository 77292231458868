import { FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import MainLoyOut from "../../../components/main-layout";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { globalSpinner, formState } from "../../../recoil/profile-atom";
import { API } from "../../../services/api-config";
import { getApiCallDynamic, postApiCallDynamicWithOutReturn, postApiCallDynamic } from "../../../services/api-service";
import CredcoCredentialsForm from "./credco-credentials-form";
import CredcoCredentialsView from "./credco-credentials-view";
import { CREDCO_INFO, ROLE_LIST, UPDATE } from "../../../constants/constatnt-variable";
import { Auth } from "../../../auth/auth";


export default function CredcoCredentials() {
    let [spinner, setSpinner] = useRecoilState(globalSpinner);

    let [formData, setFormData] = useRecoilState(formState)

    const [data, setData] = useState(null)

    let [roleVal, setRoleVal] = useState(null)
    const [hideStatus, setHideStatus] = useState(null)

    const [validationReset, setValidationReset] = useState(null)
    const [finalValidationReset, setFinalValidationReset] = useState(false)

    const getCredcoData = () => {
        setSpinner(true)

        getApiCallDynamic({ path: API.credcoCredentials }).then((res) => {
            if (res?.status === 200) {

                setData(res.data);

                setSpinner(false);
                setFinalValidationReset(false)
            }
        });
    }

    useEffect(() => {
        getCredcoData()
        // to the fetched saved data for pre filling
        fetchSavedValue();
    }, []);



    useEffect(() => {
        if (validationReset === true)
            setFinalValidationReset(true)
        else
            setFinalValidationReset(false)

    }, [validationReset]);



    const [credcoEditSection, setCredcoEditSection] = useState(false);

    // Declare State Variables: 1. Declare `isCredcoAccount` to store if the account is a Credco account, and `setHasCredCoAccount` to update it. 2. Declare `isNonCredcoAccount` to store if the account is from a different provider, and `setHasDifferentProvider` to update it. 3. Both are initialized to `null` (not set yet).
    const [isCredcoAccount, setHasCredCoAccount] = useState(null);
    const [isNonCredcoAccount, setHasDifferentProvider] = useState(null);

    console.log("formDaformDaformDaformDaformDa", formData.loanCode)

    // fetchSavedValue	Define `fetchSavedValue` to make an API call to fetch saved values and update related states upon a successful response or handle error cases. 
    const fetchSavedValue = () => {
        setSpinner(true);
        getApiCallDynamic({ path: API.getCredcoAccount,
            param: {
            loanCode: formData.loanCode
        }
         })
            .then((res) => {
                if (res?.status === 200) {
                    // Set values fetched from API
                    setHasCredCoAccount(res.data.isCredcoAccount);
                    setHasDifferentProvider(res.data.isNonCredcoAccount);
                    console.log(res.data)
                    setSpinner(false);
                    setFinalValidationReset(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching CredCo account status:', error);
                setSpinner(false);
            });
    };
    // Create a method `postApiCallDynamicWithOutReturn` to post data without expecting a response body and handle the response.
    const postAccountDetails = async (data) => {
        console.log("data of credit report:", data);


        try {
            const response = await postApiCallDynamic({

                data: data,
                refresh: (res) => {
                    return res;
                },
                path: API.postCredcoAccount,
                header: "POST"
            });
            console.log("posted the data")
            if (response?.status === 200) {
                console.log('CredCo account status updated successfully');
            } else {
                console.error('Error updating CredCo account status:', response);
            }
        } catch (error) {
            console.error('Error updating CredCo account status:', error);
        }
    };

    // 	Call handleChange with necessary parameters to save and update form data.

    const handleChange = async () => {


        await postApiCallDynamicWithOutReturn({
            data: { creditReportPullPreference: "none" },
            path: API.preferenceCredit,
            header: UPDATE
        });
        console.log("preferences :", data);
    };

    // Call handleRadioChange with necessary parameters to handle the boolean values from the user 
    const handleRadioChange = async (event) => {
        const { name, value } = event.target;

        if (name === 'credco-account') {
            setHasCredCoAccount(value === 'true'); // Save as boolean

            if (value === 'true') {
                const updatedData = {
                    isCredcoAccount: true,
                    isNonCredcoAccount: false,
                };
                await postAccountDetails(updatedData);
                fetchSavedValue()
            } else if (value === 'false') {
                handleChange()
                const updatedData = {
                    isCredcoAccount: false,
                    isNonCredcoAccount: false,
                };
                await postAccountDetails(updatedData);
                fetchSavedValue()
            }
        } else if (name === 'different-provider') {
            setHasDifferentProvider(value === 'true'); // Save as boolean
            const updatedData = {
                isCredcoAccount,
                isNonCredcoAccount: value === 'true',
            };
            await postAccountDetails(updatedData);
            fetchSavedValue()
        }
    };

    return (
        <MainLoyOut>
            <Paper>
                {/* First question for the broker and MLO */}
                <Box component="section" sx={{ px: 5, py: 3 }}>
                    {roleVal === ROLE_LIST.mlo ? (
                        <Typography variant="h4" pb={2} required>
                            Do you or your Broker have a CredCo account?
                        </Typography>
                    ) : (
                        <Typography variant="h4" pb={2} required>
                            Do you have a CredCo account?
                        </Typography>
                    )}
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="credco-account"
                            row
                            value={isCredcoAccount}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value='true' control={<Radio />} label="Yes" />
                            <FormControlLabel value='false' control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                {/* Second question, only visible if "No" is selected for the first question */}
                {isCredcoAccount === false && (
                    <Box component="section" sx={{ px: 5, py: 3 }}>
                        <Typography variant="h4" pb={2}>
                            Do you have a different Credit Report provider?
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="different-provider"
                                row
                                value={isNonCredcoAccount === null ? '' : isNonCredcoAccount.toString()} // Ensure it's a string for RadioGroup
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                <FormControlLabel value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        {isNonCredcoAccount === false && (
                            <Typography variant="body1" py={4} sx={{ color: '#f01010', fontSize: '12px' }}>
                                Please contact the Credco to get the account
                            </Typography>
                        )}
                        {isNonCredcoAccount === true && (
                            <Typography variant="body1" py={4} sx={{ color: '#f01010', fontSize: '12px' }}>
                                You will be asked to upload borrower’s Credit Report for each loan.
                            </Typography>
                        )}
                    </Box>
                )}
                {isCredcoAccount === true && (
                    <Grid container justifyContent="space-between" p={{ xs: 2, md: 5 }}>
                        <Grid
                            item
                            container
                            md={4}
                            direction="column"
                            justifyContent={"space-between"}

                        >

                            {((roleVal === ROLE_LIST.mloOwner || roleVal === ROLE_LIST.broker) ||
                                (hideStatus === false || hideStatus === null))
                                && (
                                    <Grid item>
                                        <Typography variant="body1" pb={2}>
                                            CredCo credentials will be encrypted and saved Securely in the Database.  Credentials will be validated once the Credentials are saved. Credit pull will not happen if the credentials are invalid.
                                        </Typography>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"

                                                name="radio-buttons-group"
                                            >
                                                <FormControlLabel

                                                    checked={finalValidationReset === true || data?.credcoStatus === "Not validated"}
                                                    //checked={"Not validated" == data?.credcoStatus}
                                                    value="Not validated" control={<Radio />} label="Not validated" />
                                                <FormControlLabel
                                                    checked={finalValidationReset === false && data?.credcoStatus === "Valid Credentials"}
                                                    //   checked={"Valid Credentials" == data?.credcoStatus}

                                                    value="Valid Credentials" control={<Radio />} label="Valid Credentials" />
                                                <FormControlLabel
                                                    //checked={"Invalid Credentials" == data?.credcoStatus} 
                                                    checked={finalValidationReset === false && data?.credcoStatus === "Invalid Credentials"}
                                                    value="Invalid Credentials" control={<Radio />} label="Invalid Credentials" />

                                            </RadioGroup>

                                        </FormControl>

                                    </Grid>)}
                            {(hideStatus === null || hideStatus === false) && (
                                <Grid item my={{ xs: 3, md: 0 }}>
                                    <Typography variant="body2">
                                        Last Validated: {TimeStampToMonthDateYear(data?.lastValidatedDate)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item md={4}>
                            {

                                credcoEditSection ?
                                    < CredcoCredentialsForm
                                        setCredcoEditSection={setCredcoEditSection}
                                        setData={setData}
                                        setRoleVal={setRoleVal}
                                        setValidationReset={setValidationReset}
                                        refreshData={getCredcoData}
                                        setHideStatus={setHideStatus}
                                        data={data} />
                                    :

                                    data?.credcoStatus !== "Not validated" ?
                                        < CredcoCredentialsView
                                            setCredcoEditSection={setCredcoEditSection}
                                            setValidationReset={setValidationReset}
                                            data={data}

                                        />
                                        :
                                        < CredcoCredentialsForm
                                            setCredcoEditSection={setCredcoEditSection}
                                            setData={setData}
                                            setValidationReset={setValidationReset}
                                            setHideStatus={setHideStatus}
                                            data={data}
                                            setRoleVal={setRoleVal}
                                            refreshData={getCredcoData}
                                        />
                            }

                        </Grid>
                    </Grid>
                )}
            </Paper>
        </MainLoyOut >
    );
}
